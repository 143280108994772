import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { jsPDF } from 'jspdf';
import { useAuth } from '../ServeyQuestions/AuthProvider'
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import html2canvas from 'html2canvas';
import './Calendar.css'; // Custom CSS file
import { useTranslation } from 'react-i18next';

const localizer = momentLocalizer(moment);

const CalendarComponent = ({ userId }) => {
    const [events, setEvents] = useState([]);
    const [originalEvents, setOriginalEvents] = useState([]); // Keep original events separate
    const [currentDate, setCurrentDate] = useState(new Date());
    const { pca, initialized } = useAuth(); // Get MSAL Auth
    const [loading, setLoading] = useState(false); // Fix: Define loading state
    const { t } = useTranslation();

    const getColorByScore = (score) => {
        switch (true) {
            case score >= 1 && score <= 2:
                return 'lightblue';
            case score >= 3 && score <= 4:
                return 'blue';
            case score >= 5 && score <= 6:
                return 'darkblue';
            case score >= 7 && score <= 10:
                return 'green';
            default:
                return 'gray';
        }
    };

    const roundToNearestHour = (date) => {
        const rounded = new Date(date);
        rounded.setMinutes(0, 0, 0); // Round down to the nearest hour
        return rounded;
    };

    // Function to fetch the responses and set the events
    const fetchResponses = async () => {
        try {
            const response = await getPerformanceResponses(userId);
            console.log(response)
            const eventsData = createEventsFromData(response.data); // Display only the fetched data
            setEvents(eventsData);
            console.log(eventsData)
            setOriginalEvents(eventsData); // Keep original events in a separate state
        } catch (error) {
            console.error('Error fetching performance responses:', error);
        }
    };

    // Function to calculate averages when exporting the PDF
    const calculateAveragesForExport = (data) => {
        const eventsData = [];
        const existingEventsMap = {};
        const scoresByHour = {};
        let firstRecordDay = null;
        let lastRecordDay = null; // Track the last recorded date

        data.forEach((item, index) => {
            const start = roundToNearestHour(new Date(item.timestamp));
            const color = getColorByScore(item.score);

            const event = {
                id: String(index),
                title: `Score: ${item.score}`,
                start,
                end: new Date(start.getTime() + 3600000), // 1 hour event
                allDay: false,
                backgroundColor: color,
                style: {
                    backgroundColor: color,
                    borderColor: color,
                    color: 'white',
                },
                score: item.score,
                isAveraged: false
            };

            eventsData.push(event);
            existingEventsMap[start.getTime()] = event;

            const hourKey = start.getHours();
            if (!scoresByHour[hourKey]) {
                scoresByHour[hourKey] = [];
            }
            scoresByHour[hourKey].push(item.score);

            if (!firstRecordDay || start < firstRecordDay) {
                firstRecordDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
            }

            if (!lastRecordDay || start > lastRecordDay) {
                lastRecordDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
            }
        });

        const hoursInDay = Array.from({ length: 10 }, (_, i) => i + 8); // 8 AM to 5 PM

        let currentDay = firstRecordDay;
        while (currentDay <= lastRecordDay) { // Stop at last recorded day
            const dayOfWeek = currentDay.getDay();

            if (dayOfWeek !== 6 && dayOfWeek !== 0) { // Skip weekends
                hoursInDay.forEach(hour => {
                    const eventTime = new Date(currentDay);
                    eventTime.setHours(hour);

                    if (!existingEventsMap[eventTime.getTime()] && scoresByHour[hour]?.length >= 2) {
                        const sum = scoresByHour[hour].reduce((acc, score) => acc + score, 0);
                        const averageScore = Math.floor(sum / scoresByHour[hour].length);
                        const color = getColorByScore(averageScore);

                        const averagedEvent = {
                            id: `averaged-${currentDay}-${hour}`,
                            title: `Score: ${averageScore} (Averaged)`,
                            start: eventTime,
                            end: new Date(eventTime.getTime() + 3600000), // 1 hour event
                            allDay: false,
                            backgroundColor: color,
                            style: {
                                backgroundColor: color,
                                borderColor: color,
                                color: 'white',
                            },
                            isAveraged: true,
                            score: averageScore
                        };

                        eventsData.push(averagedEvent);
                        existingEventsMap[eventTime.getTime()] = averagedEvent;
                    }
                });
            }
            currentDay = new Date(currentDay.getTime() + 86400000); // Move to the next day
        }

        return eventsData;
    };

    const getCategoryByScore = (score) => {
        if (score >= 1 && score <= 2) return 'Low Score';
        if (score >= 3 && score <= 4) return 'Moderate Score';
        if (score >= 5 && score <= 6) return 'High Score';
        if (score >= 7 && score <= 10) return 'Very High Score';
        return 'Uncategorized';
    };

    const createEvent = async (client, event) => {
        const category = getCategoryByScore(event.score);
        const outlookEvent = {
            subject: `Score: ${event.score}`,
            start: { dateTime: event.start.toISOString(), timeZone: 'UTC' },
            end: { dateTime: event.end.toISOString(), timeZone: 'UTC' },
            body: { contentType: 'HTML', content: `Performance Score: ${event.score}` },
            categories: [category]
        };

        try {
            await client.api('/me/events').post(outlookEvent);
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    const defineCategories = async (client) => {
        const categories = [
            { displayName: 'Low Score', color: 'preset7' },   // Blue
            { displayName: 'Moderate Score', color: 'preset4' }, // Green
            { displayName: 'High Score', color: 'preset1' },   // Orange
            { displayName: 'Very High Score', color: 'preset15' } // DarkRed
        ];

        for (const category of categories) {
            try {
                await client.api('/me/outlook/masterCategories')
                    .post(category);
            } catch (error) {
                if (error.statusCode === 409) {
                    console.log(`Category '${category.displayName}' already exists.`);
                } else {
                    console.error(`Error creating category '${category.displayName}':`, error);
                }
            }
        }
    };

    const handleICSExport = async () => {
        if (!initialized) {
            console.error('MSAL not initialized');
            return;
        }

        setLoading(true);

        try {
            // Authenticate user and get token
            const accounts = pca.getAllAccounts();
            if (accounts.length === 0) {
                await pca.loginPopup();
            }
            const account = pca.getAllAccounts()[0];

            let tokenResponse;
            try {
                tokenResponse = await pca.acquireTokenSilent({
                    scopes: ["Calendars.ReadWrite", "User.Read"],
                    account: account
                });
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    tokenResponse = await pca.acquireTokenPopup({
                        scopes: ["Calendars.ReadWrite", "User.Read"],
                        account: account
                    });
                } else {
                    throw error;
                }
            }

            const client = MicrosoftGraph.Client.init({
                authProvider: (done) => {
                    done(null, tokenResponse.accessToken);
                }
            });

            // Step 1: Define categories (only if they don't exist)
            await defineCategories(client);

            // Step 2: Check if "Survey Notifications" calendar exists
            const calendars = await client.api('/me/calendars').get();
            let calendarId = null;

            for (const calendar of calendars.value) {
                if (calendar.name === 'Survey Notifications') {
                    calendarId = calendar.id;
                    break;
                }
            }

            // Step 3: Create the calendar if it doesn’t exist
            if (!calendarId) {
                const newCalendar = await client.api('/me/calendars').post({
                    name: 'Survey Notifications'
                });
                calendarId = newCalendar.id;
            }

            // Step 4: Fetch events and create them
            const response = await getPerformanceResponses(userId);
            const eventsData = calculateAveragesForExport(response.data);

            for (const event of eventsData) {
                const category = getCategoryByScore(event.score);
                const outlookEvent = {
                    subject: `Score: ${event.score}`,
                    start: { dateTime: event.start.toISOString(), timeZone: 'UTC' },
                    end: { dateTime: event.end.toISOString(), timeZone: 'UTC' },
                    body: { contentType: 'HTML', content: `Performance Score: ${event.score}` },
                    categories: [category] // Assign category
                };

                // Step 5: Create event in the specified calendar
                await client.api(`/me/calendars/${calendarId}/events`).post(outlookEvent);
            }

            alert('Events added to your Outlook Calendar!');
        } catch (error) {
            console.error('Error exporting to Outlook calendar:', error);
            alert('Failed to add events to Outlook Calendar.');
        } finally {
            setLoading(false);
        }
    };








    const createEventsFromData = (data) => {
        const eventsData = [];
        data.forEach((item, index) => {
            const start = roundToNearestHour(new Date(item.timestamp));
            const color = getColorByScore(item.score);

            const event = {
                id: String(index),
                title: `Score: ${item.score}`,
                start,
                end: new Date(start.getTime() + 3600000), // 1 hour event
                allDay: false,
                backgroundColor: color,
                style: {
                    backgroundColor: color,
                    borderColor: color,
                    color: 'white', // Text color for better readability
                },
                score: item.score,
            };

            eventsData.push(event);
        });

        return eventsData;
    };

    useEffect(() => {
        fetchResponses();
    }, [userId]);

    return (
        <div className="calendar-container">
            <div className="legend-card">
                <h3>{t('calendar.scoreLegend')}</h3>
                <ul>
                    <li><span className="legend-color" style={{ backgroundColor: 'lightblue' }}></span> {t('calendar.score1_2')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'blue' }}></span> {t('calendar.score3_4')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'darkblue' }}></span> {t('calendar.score5_6')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'green' }}></span> {t('calendar.score7_10')}</li>
                    <li><span className="legend-color" style={{ backgroundColor: 'gray' }}></span> {t('calendar.noScore')}</li>
                </ul>
            </div>
            <button onClick={handleICSExport} className="export-button">Export</button>
            <div style={{ height: 'calc(100vh - 100px)', width: '100%' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '100%', width: '100%' }}
                    eventPropGetter={(event) => ({
                        style: event.style,
                    })}
                    views={['week']}
                    defaultView="week"
                    date={currentDate} // Controls the current date shown on the calendar
                    onNavigate={date => setCurrentDate(date)} // Syncs internal state with calendar navigation
                    min={new Date(1970, 1, 1, 8, 0, 0)} // Set the minimum time to 8:00 AM
                    max={new Date(1970, 1, 1, 19, 0, 0)} // Set the maximum time to 7:00 PM
                />
            </div>
        </div>
    );
};

export default CalendarComponent;
