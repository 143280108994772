import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { NotificationProvider } from './components/Notification/NotificationContext';
import AppContent from './AppContent';
import './App.css';
import CookieConsent from 'react-cookie-consent';
import './i18n';
import { AuthProvider } from './components/Dashboard/UserDashboard/ServeyQuestions/AuthProvider';

const App = () => {
    const [content, setContent] = useState('surveyIntroduction');

    return (
        <AuthProvider>
            <UserProvider setContent={setContent}>
                <Router>
                    <NotificationProvider setContent={setContent} content={content}>
                        <AppContent content={content} setContent={setContent} />
                        <CookieConsent
                            location="bottom"
                            buttonText="I accept"
                            declineButtonText="I decline"
                            cookieName="userConsentCookie"
                            style={{
                                background: "#4A4A4A",
                                color: "#FFFFFF", // Text color from your color schema
                                fontSize: "14px",
                                padding: "10px 20px",
                            }}
                            buttonStyle={{
                                background: "#FFD700",
                                color: "#4A4A4A", // Button text color
                                fontSize: "14px",
                                borderRadius: "5px",
                                margin: "0 10px"
                            }}
                            declineButtonStyle={{
                                background: "#D3D3D3",
                                color: "#4A4A4A", // Decline button text color
                                fontSize: "14px",
                                borderRadius: "5px",
                                margin: "0 10px"
                            }}
                            expires={150}
                            enableDeclineButton
                            onDecline={() => {
                                console.log("User declined cookies.");
                            }}
                            onAccept={() => {
                                console.log("User accepted cookies.");
                            }}
                        >
                            This website uses cookies to ensure you get the best experience on our website.{" "}

                        </CookieConsent>
                    </NotificationProvider>
                </Router>
            </UserProvider>
        </AuthProvider>
    );
};

export default App;